/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.css-26l3qy-menu {
  margin-top: 2px !important;
}

.h6 {
  font-size: 1.125rem !important;
}

/* .solid-bg .css-1s2u09g-control,
.solid-bg .css-1pahdxg-control,
.solid-bg .css-1pahdxg-control:hover {
  border-width: 0;
  box-shadow: 0 0 1px #f3f6f9;
  border-color: #f3f6f9;
  background-color: #f3f6f9;
  height: calc(1.5em + 1.65rem + 2px);
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.solid-bg .css-26l3qy-menu {
  background-color: #f3f6f9 !important;
}

.css-14el2xx-placeholder {
  color: #3f4254 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.search-txt:focus {
  border: 2px solid #1c88af !important;
}

.menu-item > .menu-link .menu-text {
  color: #3f4254 !important;
}
.menu-item > .menu-link .menu-text:hover {
  color: #0bb783 !important;
}

.horizontal-scrollable {
  display: flex;
  overflow-x: auto !important;
  flex-wrap: nowrap !important;
}

.horizontal-scrollable::-webkit-scrollbar {
  width: 0;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

/* Header */
@media (min-width: 992px) {
  .header .header-bottom {
    height: 70px !important;
  }
}

/* React to Print */
@page {
  size: A4;
  /* margin: 11mm 17mm 17mm 17mm; */
  margin: 1rem;
}

.report-content .header {
  display: none;
}

.report-content .footer {
  display: none;
}

@media print {
  .report-content .header {
    display: block;
  }
  .report-content .footer {
    display: block;
  }
}

.notificatonContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.menu-link.active .menu-text {
  color: #0bb783 !important;
}

/* Style for dashboard report tabs */
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .dashboard-tabs {
    font-size: 0.9rem;
  }
  .dashboard-tabs li a {
    padding: 0.5rem 0.36rem !important;
  }
}

/* --- Dashboard Report --- */
.dashboard-report {
  height: 460.63px;
}

@media only screen and (min-width: 992px) {
  .dashboard-report {
    height: 460.63px;
  }
}

.dashboard-report .table-responsive {
  overflow-x: visible !important;
}

.dashboard-report table thead tr:not(.nested) th {
  background-color: #fff !important;
  position: sticky !important;
  top: 0 !important;
}
/* ------------------- */

/* --- Expand row caret style --- */
.expand-cell-header,
.expand-cell {
  padding: 0px !important;
  border: 0px !important;
}

.reset-expansion-style {
  border: 0px !important;
}
/* ------------------- */

/* --- Scrollable --- */
.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollable:hover {
  overflow-y: overlay;
}

/* .scrollable::-webkit-scrollbar {
  background-color: #f5f5f5;
} */

.scrollable::-webkit-scrollbar {
  width: 4px;
}

.scrollable::-webkit-scrollbar:horizontal {
  height: 4px;
}

.scrollable::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0.42rem;
  background-color: #e4e6ef;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #cfd1db;
}
/* ------------------- */

@media only screen and (min-device-width: 576px) {
  .ag-root ::-webkit-scrollbar {
    width: 9px;
  }
  .ag-body-horizontal-scroll ::-webkit-scrollbar:horizontal {
    height: 9px;
  }
  
  .ag-root ::-webkit-scrollbar-thumb,
  .ag-body-horizontal-scroll ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 0.42rem;
    background-color: #e4e6ef;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  }
  
  .ag-body-horizontal-scroll ::-webkit-scrollbar-thumb:hover {
    background-color: #cfd1db;
  }
}



 .mantine-163ph1f {
   display: none !important;
 }

 .mantine-iu7vk8:focus, .mantine-iu7vk8:focus-within {
  border-color: #0BB783;
}
.mantine-1r4eeru.mantine-ref_selected_3:not(:disabled) {
  background-color: #0BB783;


}
.mantine-1ttcqj.mantine-ref_selected_3:not(:disabled) {
  background-color: #0BB783;
  color: #fff;
}
.css-1pahdxg-control{
  border-color: #0bb783 !important;
}
.mantine-iu7vk8 {
  font-size: 1rem;
  font-weight: 400;
  font-family:inherit;
  color: #3F4254;
  z-index: 3000 !important;
}

/* .mantine-1w0w92s:focus:not(.mantine-1w0w92s), .mantine-1w0w92s:focus-within:not(.mantine-1w0w92s) {
  background-color: ;
} */

.mantine-DatePicker-input {
  font-family: 'Poppins' !important;
}

.mantine-1w0w92s {
  color: #3F4254 !important;
  border-color: #f03e3e !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
border-color: black;
font-size: 1rem;
font-weight: 400;
width: 100%;
}
.react-datepicker__year .react-datepicker__year-text {
  font-size: 1rem;
  font-weight: 500;
  /* width: 200%; */
}
.react-datepicker__header {
  background-color: #fff !important;
}
.mantine-etzdje {
  background-color: #0bb783 !important;
}
.mantine-etzdje:hover {
  background-color: #0bb783 !important;
}
.react-datepicker {
height: 170px;
width: 250px;
}
.react-datepicker__year {
padding-top: 0.9rem;
padding-left: 2.4rem;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #0bb783 !important;
}
.modal.show .modal-dialog {
height: 80%;
padding-top: 30px;
}

@media (max-width: 575.98px){
  .login.login-2 .content .content-img {
    background-size: 180px !important;
    min-height: 120px;
  }
}
@media (max-width: 575.98px){
  .login.login-2 .content .content-img {
    background-size: 180px !important;
    min-height: 120px;
  }
}

.account-verification .verification-box .spinner:before {
  margin: 1rem auto;
  width: 6rem !important;
  height: 6rem !important;
  left: 40% !important;
  top: 3rem !important
}

/* @media (min-width: 576px) {
  #app {
    min-height: 100vh !important;
  }
} */

/* @media (min-width: 576px) {
  .register {
    justify-content: center !important;
  }
} */

/* Underline on hover */
.text-underline-hover:hover {
  text-decoration: underline;
  text-decoration-color: #0BB783;
  text-underline-position: under;
  text-decoration-thickness: 1px;
}

/* Header Right Controls */
@media (min-width: 992px) {
  #kt_header_menu1.header-menu .menu-nav > .menu-item .menu-submenu {
    width: 175px;
    top: 75%;
    padding: 0;
  }
}

@media (min-width: 992px) {
  #kt_header_menu1.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link {
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  #kt_header_menu1.header-menu .menu-nav > .menu-item .menu-submenu.menu-submenu-classic {
    border-radius: 0px;
    border-bottom: 1px solid #0BB783;
  }
}

/* === Quick Aside Panel === */
.offcanvas.offcanvas-on {
  right: 0 !important;
  left: auto !important;
  z-index: 1030;
}

.offcanvas {
  right: -395px !important;
}
/* ========================= */
