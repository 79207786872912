/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.grayBackGround {
  background-color: #f1f5f9;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.form-control:not(.is-invalid) {
  border-color: hsl(0, 0%, 80%);
}

.css-26l3qy-menu {
  z-index: 1001 !important;
}

.proformaTable tr td:not(.bottomNone) ,th:not(.bottomNone) {
  border-bottom: 1px solid rgb(102, 101, 101);
}

.proformaTable tr th{
  border-top: none;
}

#proformaTable tr.lastRow td{
  border-bottom: none;
}

/* BEGIN agGrid Custom css */

.last-row{
  border-bottom: 1px solid #e2e2e2 !important;
}

.cell-totalSpan {
  font-weight: bolder;
}

.ag-header-container{
  border-bottom: 1px solid #e2e2e2 !important;
}
.ag-header.ag-pivot-off{
  border-bottom: 0px solid #c50c0c !important;
}
.ag-row-level-0{
  background-color: transparent !important;
  border-bottom-style: none !important;
}

/* .ag-row-level-0 .ag-cell:not([aria-colindex^="1"]) {
  border-bottom: 1px solid #e2e2e2 !important;
} */

.ag-row-level-0 .ag-cell:not([aria-colindex="1"]):not(.fake-row), .cell-underline {
  border-bottom: 1px solid #e2e2e2 !important;
}

.ag-header-cell-text{
  font-weight: 600;
  color: #B5B5C3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.ag-theme-material{
  font-family: poppins, Helvetica, sans-serif !important;
}

/* .sales-detail-report .ag-header-cell, .sales-detail-report .ag-cell {
  padding-left: 12px !important;
  padding-right: 12px !important;
} */

.ag-custom-rowTotal {
  font-weight: bolder;
}
.redFont{
  color: red;
} 

/* END agGrid Custom css */

@page {
  size: auto !important; /* This is For Landscape Chrome Print */
}

/* for Proforma contract Of Sale */
.bodyLetter {
  margin-top: 300px;
  margin-left: 70px;
  margin-right: 70px;
  font-size: 1.50em;
}
.bodyLetter1 {
  margin-top: 200px;
  margin-left: 70px;
  margin-right: 70px;
  font-size: 1.50em;
}
.return-address {
  margin: 10px;
  text-align: right;
}

.letterSubject {
  clear: both;
  font-weight: bold;
  padding-top: 80px;
  text-align: center;
}
.greetFoot {
  float: right;
  padding-top: 50px;
}

.subject{
 padding-top: 10px;
 font-size: 1.4em;  
}
.sales-intro{
  padding-top: 20px;
}
.letterSubject1{
  clear: both;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
}
.customer-signature{
  float: right;
}
.alignleft {
	float: left;
}
.alignright {
	float: right;
}
.textbox{
  clear: both;
  padding-top: 50px;
}
/* for Proforma contract Of Sale */

@media (max-width: 991px) {
  .App {
    margin-top: 3rem;
  }
}

/* Subscription alert */
@media (min-width: 992px) {
  .subscription-alert {
    padding: 15px 0;
}}
@media (max-width: 991.98px) {
  .header-mobile-fixed .subscription-alert {
    padding-top: 15px !important;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
}}

/* .content {
  padding-top: 0px !important;
} */

.report-child { 
  display: flex; 
  justify-content: space-between;
}
.report-child tr { 
  display: block; 
  float: left; 
}
.report-child th {
  text-align: left !important;
}
.report-child td {
  text-align: right;
}
.report-child th, .report-child td { 
  display: block; 
}

.report-table th, .report-table td {
  font-size: 1rem !important;
}

/* ========== Footer Menu Start ========== */
#footer-menu .fa-2x{
  font-size: 21px !important;
}

#footer-menu.navbar {
  padding-top: 0.15rem !important;
  padding-bottom: 0.15rem !important;
  background-color: #fff !important;
  box-shadow: 0px -3px 10px 0px rgba(177, 167, 167, 0.5);
}

@media (max-width: 991px) {
  #footer-menu .dropdown-menu {
    max-width: 30vw !important;
    min-width: 20vw !important;
    /* left: -50px !important; */
  }
}

@media (max-width: 600px) {
  #footer-menu .dropdown-menu{
    max-width: 40vw !important;
    /* min-width: 30vw !important; */
    /* left: -50px !important; */
  }
}

#footer-menu .dropdown-menu {
  left: 80%;
  transform: translateX(-80%);
}

/* #footer-menu .more-menus .dropdown-menu {
  min-width: 1vw !important;
  max-width: 20vw !important;
} */

#footer-menu .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after {
  content: '';
}

.sub-menu ul {
  padding-left: 0;
}

.sub-menu li {
  display: block;
  padding: 0.75rem 1.25rem 0;
}

.sub-menu a.submenu-item:hover .menu-text {
  color: rgb(11, 183, 131) !important;
}

.submenu-item {
  display: block;
}

.nested-menu .submenu-item {
  display: block;
  /* padding-left: 20px; */
}

#footer-menu i {
  color: rgb(11, 183, 131);
}

#footer-menu .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after {
  margin-left: 0rem;
}

#footer-menu a.nav-link.active {
  box-shadow: 0px 2px 0px 0px rgba(11, 183, 131, 0.5);
}

#footer-menu .dropdown-item:hover {
  background-color: #fff;
  color: #181C32;
}
/* ========== Footer Menu End ========== */



/* ========== Footer Mobile Start ========== */
@media (max-width: 991px) {
  .footer {
    margin-bottom: 39px !important;
  }
}
/* ========== Footer Mobile End ========== */


@media (max-width: 991.98px) {
  .topbar-mobile-on .topbar {
    margin-top: 0 !important;
    height: 40px !important;
  }

  #mobile-dropdown .quick-search-wrapper {
    height: auto !important;
    max-height: 200px !important;
  }
}

@media (min-width: 991.98px) {
  .header-bottom {
    margin-top: 75px !important;
  }

  #desktop-dropdown .quick-search-wrapper {
    height: auto !important;
    max-height: 350px !important;
  }
}

.transactions-list .card-header {
  padding: 1.25rem 2rem 2rem !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}


/* ========== Header Notification Start ========== */
#header-notification.showNotification {
  position: absolute;
  top: 0px;
  left: 0px;
  will-change: transform;
  display: block;
}
#header-notification.showNotification.show {
  display: none;
}
@media (max-width: 991.98px) {
  #header-notification.showNotification {
    transform: translate3d(-189px, 39px, 0px);
  }
}

@media (min-width: 991.98px) {
  #header-notification.showNotification {
    transform: translate3d(-292px, 75px, 0px);
  }
}
/* ========== Header Notification End ========== */

/* Closed Transaction Groups Table */
.closed-transactions-table {
  max-height: 350px;
}

/* Disabled anchor tag */
a.disabled {
  pointer-events: none;
  cursor: default;
}

.apexcharts-yaxistooltip, .apexcharts-xaxistooltip {
  display: none;
}

/* Car filter multi-select */
.css-1p3m7a8-multiValue, .css-wsp0cs-MultiValueGeneric {
  overflow: visible !important;
}

/* Logged out header style */
.headerLoggedOut {
  margin-bottom: 75px;
}

@media (max-width: 991px) {
  .headerLoggedOut {
    margin-bottom: 3rem;
  }
}
